.nav {
	padding: 3rem 0;
	background: var(--bg-dark);

	&--home {
		position: absolute;
		background: transparent;
		width: 100%;
		left: 0;
		top: 0;
		.nav__submenu {
			li a {
				background: var(--bg-dark);
			}
		}
	}

	&__row {
		display: flex;
		justify-content: space-between;
	}

	&__brand {
		color: #fff;
		font-size: 2.2rem;
		font-weight: 700;
		text-transform: uppercase;
		transition: opacity 0.15s ease-in-out;
		&:hover {
			opacity: 0.7;
		}
	}

	&__side {
		display: flex;
	}

	ul {
		padding-left: 0;
		li {
			list-style: none;
		}
	}

	&__navmenu {
		display: flex;
		margin: 0;
	}

	&__caret {
		font-size: 1.6rem;
		padding-left: 0.6rem;
	}

	&__social {
		a {
			display: block;
			padding: 0 1.2rem;
			font-size: 1.8rem;
			color: #fff;
			opacity: 0.7;
			transition: opacity 0.15s ease-in-out;
			line-height: 1.25;
			&:hover {
				opacity: 0.9;
			}
		}
	}

	&__menu {
		margin-left: 1.6rem;
		li {
			position: relative;
			&:hover,
			&:focus {
				.nav__submenu {
					display: block;
				}
				> a {
					color: #fff;
				}
			}
		}
		a {
			display: block;
			padding: 0 15px;
			font-size: 1.6rem;
			color: rgba(#fff, 0.7);
			line-height: 1.5625;
			transition: color 0.15s ease;
			&:hover,
			&.active {
				color: #fff;
			}
			&.active {
				font-weight: 600;
			}
		}
	}
	&__submenu {
		display: none;
		position: absolute;
		top: 20px;
		padding-top: 10px;
		left: 15px;
		li {
			a {
				padding: 0 12px;
				line-height: 2.4;
				font-weight: 600;
				font-size: 1.3rem;
				background: var(--bg-light);
			}
			&:not(:last-child) a {
				border-bottom: 1px solid rgba(#fff, 0.1);
			}
		}
	}
}

@media (max-width: 690px) {
	.nav {
		&__menu {
			display: none;
		}
		&__social {
			a {
				padding: 0 1rem;
			}
		}
	}
}
