.breset {
	background: transparent;
	-webkit-appearance: none;
	padding: 0;
	cursor: pointer;
	margin: 0;
	outline: none;
	box-shadow: none;
	border-radius: 0;
	border: 0;
}

@import './form';
