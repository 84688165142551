.home-header {
	background: linear-gradient(to top right, var(--bg-dark), var(--bg-light));
	padding: 18.5rem 0 13rem 0;
	&__tagline {
		text-align: center;
		margin: 0 auto;
		color: #ddd;
		font-weight: 400;
		max-width: 83rem;
		padding: 0 15px;
		width: 100%;
		line-height: 1.25;
		font-size: 3rem;
	}
}

.home-project {
	padding: 15rem 0;
	&__inner {
		position: relative;
	}
	&__canvas {
		background: #fff;
		img {
			object-fit: cover;
			object-position: top left;
		}
	}
	&__cover {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		display: none;
	}
	&__description {
		padding: 3.2rem 2.4rem;
		background: #fff;
		border-radius: 2px;
	}
	&__blurb {
		font-size: 1.6rem;
		line-height: 1.7;
		padding-top: 1rem;
		color: rgba(#000, 0.8);
	}
	&__title {
		font-size: 2.4rem;
		line-height: 1.15;
		margin: 0;
		font-weight: 700;
	}
	&__link {
		margin-top: 1.2rem;
		font-size: 1.6rem;
		margin-bottom: 0;
		display: block;
	}
}

@media (min-width: 901px) {
	.home-project {
		&__canvas {
			width: 60%;
			height: 500px;
			position: relative;
			box-shadow: 0 25px 50px -12px rgba(#000, 0.25);
			img {
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
			}
		}
		&__description {
			position: absolute;
			width: 40%;
			left: 50%;
			top: 50%;
			transform: translateY(-50%);
			box-shadow: 0 25px 50px -12px rgba(#000, 0.25);
		}
	}
}

@media (max-width: 900px) {
	.home-project {
		padding: 7.5rem 0;
		&__canvas {
			img {
				width: 100%;
				max-height: calc(100vh * 1.2);
			}
		}
		&__inner {
			box-shadow: 0 25px 50px -12px rgba(#000, 0.25);
			border-radius: 3px;
			overflow: hidden;
		}
		&__cover {
			display: block;
		}
	}
}
