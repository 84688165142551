* {
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
}

html,
body {
	font-size: 62.5%;
}

body {
	font-family: 'Open Sans', sans-serif;
	background: var(--bg-light-grey);
}

.container {
	max-width: 1190px;
	width: 100%;
	padding: 0 1.5rem;
	margin: 0 auto;
}

.layout-wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.top {
	flex: 1 1;
}

a {
	text-decoration: none;
	color: var(--light-blue);
}

@import './navigation';
@import './footer';
