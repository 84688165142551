.fgroup {
	margin-bottom: 1.4rem;
}

.flabel {
	font-weight: 600;
	color: #232323;
	font-size: 1.4rem;
	display: block;
	margin-bottom: 0.6rem;
}

.finput {
	display: block;
	width: 100%;
	border: 1px solid #bbb;
	border-radius: 4px;
	box-shadow: 0;
	padding: 1rem 1.4rem;
	outline: none;
	&:focus {
		border-color: var(--light-blue);
	}
}

select.finput {
	padding-top: 0;
	padding-bottom: 0;
	height: 4rem;
}

.finput {
	&:not(select) {
		-webkit-appearance: none;
		line-height: 1.15;
	}
}

.finput,
.fbutton {
	font-family: inherit;
	font-size: 1.6rem;
}

.ftextarea {
	min-height: 20rem;
}

.fbutton {
	padding: 1.2rem 2rem;
	border-radius: 3px;
	background: var(--bg-light);
	letter-spacing: 0.4px;
	font-size: 1.4rem;
	text-transform: uppercase;
	color: #fff;
	font-weight: 700;
	box-shadow: inset 0 0 0 3px rgba(#000, 0.2);
	transition: background 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
	&:hover,
	&:focus {
		background: lighten($bg-light, 4);
		box-shadow: none;
	}
}
