.footer {
	margin-top: 4.8rem;
	background: var(--bg-dark);
	padding: 5rem 0;
	text-align: center;
	&__copyright {
		color: rgba(#fff, 0.8);
		line-height: 1.5;
		font-size: 1.4rem;
		margin: 0;
	}
	&__links {
		margin: 0 0 1.8rem;
		a {
			font-size: 1.6rem;
			color: #fff;
			opacity: 0.7;
			padding: 0 6px;
			transition: opacity 0.15s ease-in-out;
			&:hover {
				opacity: 0.9;
			}
		}
	}
	&__mark {
		display: block;
		color: #fff;
		font-size: 2.2rem;
		font-weight: 700;
		margin: 0 0 1.2rem;
		text-transform: uppercase;
	}
}
