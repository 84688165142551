@import './reset';

@import 'utils/index';
@import 'layout/index';
@import 'modules/index';
@import 'pages/index';

:root {
	--dark: #{$dark};
	--bg-light: #{$bg-light};
	--bg-dark: #{$bg-dark};
	--bg-light-grey: #{$bg-light-grey};
	--light-blue: #{$light-blue};
}

em {
	font-style: italic;
}

strong {
	font-weight: 700;
}

.page {
	padding: 3.2rem 0;

	h1 {
		font-size: 3.2rem;
		padding: 1rem 0;
	}

	h2,
	h3 {
		margin: 1.4rem 0;
		line-height: 1.15;
		font-weight: 700;
	}

	h2 {
		font-size: 2.4rem;
	}

	h3 {
		font-size: 1.8rem;
	}

	p {
		font-size: 1.6rem;
		line-height: 1.5;
		padding: 1rem 0;
		color: #141414;
	}

	ul {
		margin-top: 1.6rem;
		padding-left: 4rem;
		li {
			list-style-type: disc;
			font-size: 1.6rem;
			line-height: 1.5;
		}
	}
}
