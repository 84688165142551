html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

html, body {
  font-size: 62.5%;
}

body {
  background: var(--bg-light-grey);
  font-family: Open Sans, sans-serif;
}

.container {
  max-width: 1190px;
  width: 100%;
  margin: 0 auto;
  padding: 0 1.5rem;
}

.layout-wrapper {
  min-height: 100vh;
  flex-direction: column;
  display: flex;
}

.top {
  flex: 1;
}

a {
  color: var(--light-blue);
  text-decoration: none;
}

.nav {
  background: var(--bg-dark);
  padding: 3rem 0;
}

.nav--home {
  width: 100%;
  background: none;
  position: absolute;
  top: 0;
  left: 0;
}

.nav--home .nav__submenu li a {
  background: var(--bg-dark);
}

.nav__row {
  justify-content: space-between;
  display: flex;
}

.nav__brand {
  color: #fff;
  text-transform: uppercase;
  font-size: 2.2rem;
  font-weight: 700;
  transition: opacity .15s ease-in-out;
}

.nav__brand:hover {
  opacity: .7;
}

.nav__side {
  display: flex;
}

.nav ul {
  padding-left: 0;
}

.nav ul li {
  list-style: none;
}

.nav__navmenu {
  margin: 0;
  display: flex;
}

.nav__caret {
  padding-left: .6rem;
  font-size: 1.6rem;
}

.nav__social a {
  color: #fff;
  opacity: .7;
  padding: 0 1.2rem;
  font-size: 1.8rem;
  line-height: 1.25;
  transition: opacity .15s ease-in-out;
  display: block;
}

.nav__social a:hover {
  opacity: .9;
}

.nav__menu {
  margin-left: 1.6rem;
}

.nav__menu li {
  position: relative;
}

.nav__menu li:hover .nav__submenu, .nav__menu li:focus .nav__submenu {
  display: block;
}

.nav__menu li:hover > a, .nav__menu li:focus > a {
  color: #fff;
}

.nav__menu a {
  color: #ffffffb3;
  padding: 0 15px;
  font-size: 1.6rem;
  line-height: 1.5625;
  transition: color .15s;
  display: block;
}

.nav__menu a:hover, .nav__menu a.active {
  color: #fff;
}

.nav__menu a.active {
  font-weight: 600;
}

.nav__submenu {
  padding-top: 10px;
  display: none;
  position: absolute;
  top: 20px;
  left: 15px;
}

.nav__submenu li a {
  background: var(--bg-light);
  padding: 0 12px;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 2.4;
}

.nav__submenu li:not(:last-child) a {
  border-bottom: 1px solid #ffffff1a;
}

@media (max-width: 690px) {
  .nav__menu {
    display: none;
  }

  .nav__social a {
    padding: 0 1rem;
  }
}

.footer {
  background: var(--bg-dark);
  text-align: center;
  margin-top: 4.8rem;
  padding: 5rem 0;
}

.footer__copyright {
  color: #fffc;
  margin: 0;
  font-size: 1.4rem;
  line-height: 1.5;
}

.footer__links {
  margin: 0 0 1.8rem;
}

.footer__links a {
  color: #fff;
  opacity: .7;
  padding: 0 6px;
  font-size: 1.6rem;
  transition: opacity .15s ease-in-out;
}

.footer__links a:hover {
  opacity: .9;
}

.footer__mark {
  color: #fff;
  text-transform: uppercase;
  margin: 0 0 1.2rem;
  font-size: 2.2rem;
  font-weight: 700;
  display: block;
}

.breset {
  -webkit-appearance: none;
  cursor: pointer;
  box-shadow: none;
  background: none;
  border: 0;
  border-radius: 0;
  outline: none;
  margin: 0;
  padding: 0;
}

.fgroup {
  margin-bottom: 1.4rem;
}

.flabel {
  color: #232323;
  margin-bottom: .6rem;
  font-size: 1.4rem;
  font-weight: 600;
  display: block;
}

.finput {
  width: 100%;
  box-shadow: 0;
  border: 1px solid #bbb;
  border-radius: 4px;
  outline: none;
  padding: 1rem 1.4rem;
  display: block;
}

.finput:focus {
  border-color: var(--light-blue);
}

select.finput {
  height: 4rem;
  padding-top: 0;
  padding-bottom: 0;
}

.finput:not(select) {
  -webkit-appearance: none;
  line-height: 1.15;
}

.finput, .fbutton {
  font-family: inherit;
  font-size: 1.6rem;
}

.ftextarea {
  min-height: 20rem;
}

.fbutton {
  background: var(--bg-light);
  letter-spacing: .4px;
  text-transform: uppercase;
  color: #fff;
  border-radius: 3px;
  padding: 1.2rem 2rem;
  font-size: 1.4rem;
  font-weight: 700;
  transition: background .1s ease-in-out, box-shadow .1s ease-in-out;
  box-shadow: inset 0 0 0 3px #0003;
}

.fbutton:hover, .fbutton:focus {
  box-shadow: none;
  background: #2b5a86;
}

.home-header {
  background: linear-gradient(to top right, var(--bg-dark), var(--bg-light));
  padding: 18.5rem 0 13rem;
}

.home-header__tagline {
  text-align: center;
  color: #ddd;
  max-width: 83rem;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  font-size: 3rem;
  font-weight: 400;
  line-height: 1.25;
}

.home-project {
  padding: 15rem 0;
}

.home-project__inner {
  position: relative;
}

.home-project__canvas {
  background: #fff;
}

.home-project__canvas img {
  object-fit: cover;
  object-position: top left;
}

.home-project__cover {
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.home-project__description {
  background: #fff;
  border-radius: 2px;
  padding: 3.2rem 2.4rem;
}

.home-project__blurb {
  color: #000c;
  padding-top: 1rem;
  font-size: 1.6rem;
  line-height: 1.7;
}

.home-project__title {
  margin: 0;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.15;
}

.home-project__link {
  margin-top: 1.2rem;
  margin-bottom: 0;
  font-size: 1.6rem;
  display: block;
}

@media (min-width: 901px) {
  .home-project__canvas {
    width: 60%;
    height: 500px;
    position: relative;
    box-shadow: 0 25px 50px -12px #00000040;
  }

  .home-project__canvas img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .home-project__description {
    width: 40%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
    box-shadow: 0 25px 50px -12px #00000040;
  }
}

@media (max-width: 900px) {
  .home-project {
    padding: 7.5rem 0;
  }

  .home-project__canvas img {
    width: 100%;
    max-height: 120vh;
  }

  .home-project__inner {
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0 25px 50px -12px #00000040;
  }

  .home-project__cover {
    display: block;
  }
}

.contact {
  margin-top: 3rem;
}

.contact__alert {
  border: 1px solid #0000;
  margin-top: 3rem;
  padding: 1.2rem 2rem;
  font-size: 1.6rem;
  display: none;
}

.contact__alert--show {
  animation: .15s ease-in-out fadeIn;
  display: block;
}

.contact__alert--success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.contact__alert--error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

@media (min-width: 800px) {
  .contact__row {
    flex-direction: row;
    margin: 0 -1.5rem;
    display: flex;
  }

  .contact__cell {
    min-width: 0;
    flex: 1;
    padding: 0 1.5rem;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

:root {
  --dark: #073642;
  --bg-light: #265077;
  --bg-dark: #022140;
  --bg-light-grey: #efefef;
  --light-blue: #3182ce;
}

em {
  font-style: italic;
}

strong {
  font-weight: 700;
}

.page {
  padding: 3.2rem 0;
}

.page h1 {
  padding: 1rem 0;
  font-size: 3.2rem;
}

.page h2, .page h3 {
  margin: 1.4rem 0;
  font-weight: 700;
  line-height: 1.15;
}

.page h2 {
  font-size: 2.4rem;
}

.page h3 {
  font-size: 1.8rem;
}

.page p {
  color: #141414;
  padding: 1rem 0;
  font-size: 1.6rem;
  line-height: 1.5;
}

.page ul {
  margin-top: 1.6rem;
  padding-left: 4rem;
}

.page ul li {
  font-size: 1.6rem;
  line-height: 1.5;
  list-style-type: disc;
}

/*# sourceMappingURL=contact.cdb77925.css.map */
