.contact {
	margin-top: 3rem;

	&__alert {
		display: none;
		border: 1px solid transparent;
		margin-top: 3rem;
		font-size: 1.6rem;
		padding: 1.2rem 2rem;

		&--show {
			display: block;
			animation: fadeIn 150ms ease-in-out;
		}
		&--success {
			color: #155724;
			background-color: #d4edda;
			border-color: #c3e6cb;
		}
		&--error {
			color: #721c24;
			background-color: #f8d7da;
			border-color: #f5c6cb;
		}
	}
}

@media (min-width: 800px) {
	.contact {
		&__row {
			display: flex;
			flex-direction: row;
			margin: 0 -1.5rem;
		}
		&__cell {
			padding: 0 1.5rem;
			flex: 1;
			min-width: 0;
		}
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translateY(10px);
	}
	to {
		opacity: 1;
		transform: translateX(0px);
	}
}
